// @ts-expect-error: old javascript
import { differenceInCalendarDays } from "date-fns";
import { logError } from './app.ts';
import { NoSleep } from './nosleep.js';
import { getSessionStats, getSetting, SETTINGS } from './settings.js';
import type { SessionStats } from './settings.js';

const noSleep = new NoSleep();

export const preventScreenSleep = async (block: boolean, delay: boolean = false) => {
    try {
        if (block) {
            if (!delay) {
                await noSleep.enable(); // async block
            } else {
                noSleep.enableOnNextVisiblity(); // delayed, not async
            }
        }
        else {
            await noSleep.disable();
        }
        // on error, should re-enable using visibility listener
    } catch (err: any) { // eslint-disable-line  @typescript-eslint/no-explicit-any
        logError(`Error screen sleep to block=${block}`, err);
    }
}

export const getStatsSessionNumber = () => {
    return getSessionStats().length;
}

interface Stats {
    totalMins: number,
    totalBreaths: number,
    currentStreak: number,
    bestStreak: number,
    /** Sorted in ascending order */
    sessions: SessionStats[],
    /** grouped by year/month/day */
    dateGroups: Record<string, SessionStats[]>,
}

export const getStats = (): Stats => {
    return computeStats(getSessionStats());
}

export const computeStats = (sessions: SessionStats[]): Stats => {
    let lastDate: Date | null = null;
    sessions = [...sessions].sort((a, b) => a.date - b.date); // copy, sort by date

    const stats: Stats = {
        totalMins: 0, totalBreaths: 0, currentStreak: 0,
        bestStreak: 0, sessions: sessions, dateGroups: {}
    };
    const breathTime = getSetting(SETTINGS.breathing_time) as number || 5;
    const groups = stats.dateGroups;

    for (const sess of sessions) {
        const seconds = sess.elapsed
        const minutes = Math.round(seconds / 60); // in seconds

        stats.totalMins += minutes
        stats.totalBreaths += Math.round(seconds / (breathTime * 2)) // TODO use settings breath time

        if (!sess.date) continue; // no date
        const sessDate = new Date(sess.date);

        // compute streaks
        if (lastDate !== null) {
            const daysDiff = differenceInCalendarDays(sessDate, lastDate);
            if (daysDiff > 1) {
                stats.currentStreak = 1; // not in streak anymore, start new streak
            } else if (daysDiff === 1) { // same day
                stats.currentStreak++; // another day in streak
                stats.bestStreak = Math.max(stats.bestStreak, stats.currentStreak);
            }
        } else { // no last date (first date)
            stats.currentStreak++;
            stats.bestStreak++;
        }
        lastDate = sessDate;

        // group by day/month/year
        const addToGroup = (key: string, se: SessionStats) => {
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(se);
        };
        const year = sessDate.getFullYear();
        const month = sessDate.getMonth();
        const day = sessDate.getDate();
        addToGroup(`${year}-${month + 1}-${day}`, sess)
        addToGroup(`${year}-${month + 1}`, sess)
        addToGroup(`${year}`, sess)
    }

    return stats;
}


