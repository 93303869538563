<script>
  import { Page } from "framework7-svelte";
  import { getLanguage, translate as trsl } from "../js/i18n.ts";

  import { f7ready } from "framework7-svelte";
  import { onDestroy, onMount } from "svelte";
  import { DriftCorrectingTimer } from "../js/media.ts";

  export let f7router;
  export let duration = 5;

  let langSelectEl;
  let currentLang = getLanguage();
  let tr = trsl; // store translate fn so it can be refresh on lang chjange for svelte to update ui

  let timerLeft = "00:00";
  const countDownDate = Date.now();
  const ignoredSeconds = 0;
  var countDownComplete = false;

  const timerFunc = () => {
    if (!countDownDate) return;
    // Get today's date and time
    const now = new Date().getTime();
    // Find the distance between now and the count down date
    const distance = now - countDownDate *  1000;
    // Time calculations for days, hours, minutes and seconds
    // const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    // const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let mins = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let secs = Math.floor((distance % (1000 * 60)) / 1000);
    // Display the result in the element with id="timer"
    if (distance < 0) {
      // TODO use ref variable, better math, use constants
      mins = Math.ceil(mins - (distance % (1000 * 60 * 60)) / (1000 * 60));
      secs = Math.ceil((distance % (1000 * 60)) / 1000);
    }
    // update UI
    timerLeft = `${mins}:${secs}`;
  };

  const elapsedTimer = new DriftCorrectingTimer(timerFunc, 100); // update every 100ms

  onMount(() => {
    f7ready(() => {});
    /* now play three times as fast just for the heck of it */
    document.querySelector("video").playbackRate = 0.6;
    timerFunc();
    elapsedTimer.start();
  });

  onDestroy(() => {
    elapsedTimer.stop();
  });

</script>

<Page name="timer" class="no-default-bg">
  <div class="home">
    <video autoplay loop muted playsinline>
      <source
        src="https://cdn.pixabay.com/video/2021/06/13/77560-563002049_large.mp4"
        type="video/mp4" />
      There is a problem loading the background animation.
    </video>

    <div class="home-content">
      <h1>{timerLeft}</h1>
      <p>EXPERIENCE</p>

      <!-- <button>EXPLORE THE WORLD</button> -->
    </div>

    <!-- Optional: some overlay text to describe the video -->
    <!-- <div class="content">
      <h1>Heading</h1>
      <p>Lorem ipsum...</p>
      <button id="myBtn" onclick="myFunction()">Pause</button>
    </div> -->
  </div>
</Page>

<style>
  *,
  ::before,
  ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: roboto, sans-serif;
  }

  .home {
    height: 100vh;
    position: relative;
  }

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .home-content {
    position: relative;
    padding-top: 150px;
    color: #fff;
    text-align: center;
  }

  /* Add some content at the bottom of the video/page */
  .content {
    position: fixed;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    width: 100%;
    padding: 20px;
  }

  /* Style the button used to pause/play the video */
  #myBtn {
    width: 200px;
    font-size: 18px;
    padding: 10px;
    border: none;
    background: #000;
    color: #fff;
    cursor: pointer;
  }

  #myBtn:hover {
    background: #ddd;
    color: black;
  }

  h1 {
    font-family:
      Playfair Display,
      serif;
    font-size: clamp(45px, 7vw, 130px);
    line-height: 1.1;
  }

  .home p {
    font-size: clamp(25px, 4vw, 40px);
    margin-top: 10px;
  }

  .home-content button {
    display: block;
    font-size: clamp(14px, 1.5vw, 18px);
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    background: transparent;
    color: #fff;
    margin: 50px auto 0;
    padding: 12px 20px;
    cursor: pointer;
  }
</style>
