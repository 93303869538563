<script>
  import { Icon, List, ListItem, Navbar, Page } from "framework7-svelte";
  import {
    getLanguage,
    languages,
    setLanguage,
    translate as trsl
  } from "../js/i18n.ts";

  import { f7ready } from "framework7-svelte";
  import { onMount } from "svelte";
  import { shareApp } from "../js/app.ts";

  export let f7router;

  let langSelectEl;
  let currentLang = getLanguage();
  let tr = trsl; // store translate fn so it can be refresh on lang chjange for svelte to update ui

  onMount(() => {
    f7ready(() => {
      const smartSelect = langSelectEl.smartSelectInstance();
      smartSelect.on("close", () => {
        const lang = smartSelect.getValue();
        currentLang = lang;
        setLanguage(lang);
        // forece refresh UI for lang to change
        tr = trsl;
      });
    });
  });
</script>

<Page name="settings">
  <Navbar title={tr("settings.title")} backLink="Back"></Navbar>

  <List>
    <li>
      <a class="item-link item-content" href="/settings/about/">
        <div class="item-media">
          <img src="/icons/icon.svg" alt="Coherence Logo" />
        </div>
        <div class="item-inner">
          <div class="item-title">{tr("settings.about")}</div>
        </div>
      </a>
    </li>
    <li>
      <a class="item-link item-content" href="/settings/session/">
        <div class="item-media">
          <i class="icon material-icons">headphones</i>
        </div>
        <div class="item-inner">
          <div class="item-title">{tr("settings.session_media")}</div>
        </div>
      </a>
    </li>
    <li>
      <a class="item-link item-content" href="/settings/statistics/">
        <div class="item-media">
          <i class="icon material-icons">bar_chart</i>
        </div>
        <div class="item-inner">
          <div class="item-title">{tr("settings.statistics")}</div>
        </div>
      </a>
    </li>
    <li>
      <a class="item-link item-content" href="/settings/notifications/">
        <div class="item-media">
          <i class="icon material-icons">alarm</i>
        </div>
        <div class="item-inner">
          <div class="item-title">{tr("settings.notifications")}</div>
        </div>
      </a>
    </li>
    <ListItem
      title={tr("settings.language")}
      smartSelect
      bind:this={langSelectEl}>
      <Icon slot="media" material="language" size="32"></Icon>
      <select name="language">
        {#each Object.entries(languages) as [code, name]}
          {#if code == currentLang}
            <option selected value={code}>{name}</option>
          {:else}
            <option value={code}>{name}</option>
          {/if}
        {/each}
      </select>
    </ListItem>
    <li>
      <a class="item-link item-content" href="/settings/licenses/">
        <div class="item-media">
          <i class="icon material-icons">people</i>
        </div>
        <div class="item-inner">
          <div class="item-title">
            {tr("settings.credits_and_licenses")}
          </div>
        </div>
      </a>
    </li>
    <li>
      <a class="item-link item-content" on:click={shareApp} href={"#"}>
        <div class="item-media">
          <i class="icon material-icons">share</i>
        </div>
        <div class="item-inner">
          <div class="item-title">{tr("common.share")}</div>
        </div>
      </a>
    </li>
  </List>
</Page>

<style>
  .item-content .material-icons {
    font-size: 32px;
  }
</style>
