<script>
  import { f7, f7ready } from "framework7-svelte";
  import { onMount } from "svelte";
  import { dispatch, EVENT } from "../js/app.ts";
  import tr from "../js/i18n.ts";
  import { SETTINGS, getSettings, setSettings } from "../js/settings.ts";

  export let showBreathTime = false;
  let formEl;
  let initFinished = false;

  const isTrue = (data, key) => {
    if (!(key in data)) return false;

    let value = data[key];
    if (Array.isArray(value)) {
      value = value.length ? value[0] : false;
    }
    if (value && value !== "off") {
      return true;
    }
    return false;
  };

  const getFormEl = () => {
    return formEl;
  };

  const onSettingsChange = () => {
    if (!initFinished) return; // avoid on:change events when init() isbeing called
    const data = f7.form.convertToData(getFormEl());
    if (!data) return;
    // convert form data to settings object
    const newSettings = {};
    newSettings[SETTINGS.sound_effects_enabled] = isTrue(
      data,
      SETTINGS.sound_effects_enabled
    );
    newSettings[SETTINGS.vibrations_enabled] = isTrue(
      data,
      SETTINGS.vibrations_enabled
    );
    newSettings[SETTINGS.background_music_enabled] = isTrue(
      data,
      SETTINGS.background_music_enabled
    );
    newSettings[SETTINGS.messages_enabled] = isTrue(
      data,
      SETTINGS.messages_enabled
    );

    if (showBreathTime) {
      // only update if visible
      newSettings[SETTINGS.breathing_time] = Number(
        data[SETTINGS.breathing_time]
      );
    }
    // update stored settings
    setSettings(newSettings);
    // let listeners know we've updated the settings
    dispatch(EVENT.settingsChanged, newSettings);
  };

  function init() {
    const settings = getSettings();
    const data = { ...settings };
    // convert boolean to form bool values
    Object.keys(data).forEach((key) => {
      const val = data[key];
      if (typeof val == "boolean") {
        // bools
        data[key] = val ? ["on"] : ["off"]; // toogles expect that format
      } else if (typeof val == "number") {
        data[key] = val.toString(); // number to string
      }
    });
    // fill data
    f7.form.fillFromData(getFormEl(), data);

    // set the value of range
    const range = f7.range.get(".range-slider");
    if (range) {
      // can be hidden
      range.formatLabel = (value) => value.toFixed(1); // cleaner display
      range.setValue(settings[SETTINGS.breathing_time]); // need
    }

    initFinished = true;
  }

  onMount(() => {
    f7ready(() => {
      init();
    });
  });
</script>

<form
  class="list simple-list session-settings-form"
  bind:this={formEl}
  on:change={onSettingsChange}
  on:submit|preventDefault={onSettingsChange}>
  <ul>
    <li>
      <span>{tr("settings.sound_effects")}</span>
      <label class="toggle">
        <input name={SETTINGS.sound_effects_enabled} type="checkbox" />
        <span class="toggle-icon"></span>
      </label>
    </li>
    <li>
      <span>{tr("settings.background_music")}</span>
      <label class="toggle">
        <input name={SETTINGS.background_music_enabled} type="checkbox" />
        <span class="toggle-icon"></span>
      </label>
    </li>
    <li>
      <span>{tr("settings.vibrations")}</span>
      <label class="toggle">
        <input name={SETTINGS.vibrations_enabled} type="checkbox" />
        <span class="toggle-icon"></span>
      </label>
    </li>
    <li>
      <span>{tr("settings.messages")}</span>
      <label class="toggle">
        <input name={SETTINGS.messages_enabled} type="checkbox" />
        <span class="toggle-icon"></span>
      </label>
    </li>
    {#if showBreathTime}
      <li>
        <span>{tr("settings.breathing_time_secs")}</span>
        <div
          class="range-slider range-slider-init"
          style="margin-left: 1em"
          data-label="true"
          data-scale="true"
          data-min="3"
          data-max="7"
          data-step="0.1"
          data-scale-steps="4"
          data-scale-sub-steps="10">
          <input
            type="range"
            name={SETTINGS.breathing_time}
            min="3"
            max="7"
            step="0.1" />
        </div>
      </li>
    {/if}
  </ul>
</form>

<style>
  .session-settings-form .range-slider {
    margin-left: 8px;
    margin-right: 8px;
  }
</style>
