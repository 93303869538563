
<style>
  :global(#live-settings.list) {
    margin: 8px 0;
  }

  .icon.icon-pause {
    font-size: 2em;
  }

  :global(.icon-badge) {
    opacity: 0;
  }

  :global(.icon.icon-badge) {
    font-size: 4em;
    color: darkgoldenrod;
  }

  .fab.fab-center-bottom.fab-morph {
    background-color: unset;
  }

  :global(
    .fab-left-bottom,
    .fab-center-bottom,
    .fab-right-bottom) {
    bottom: 15vh;
  }

  :global(.grid.finish-dialog) {
    grid-template-columns: 10fr 90fr;
    align-items: center;
  }


</style>

<script>
  import { f7, Page } from "framework7-svelte";
  import MessageComponent from "../components/session-messages.svelte";
  import SettingsComponent from "../components/session-settings.svelte";
  import "../css/sunrise.css";
  import {
    recordEventSessionEnd,
    recordEventSessionStart
  } from "../js/analytics.ts";
  import { dispatch, EVENT, subscribe, unsubscribe } from "../js/app.ts";
  import { translate as tr } from "../js/i18n.ts";
  import {
    BREATH_IN_PATH,
    BREATH_OUT_PATH,
    CHIMES_1,
    DEFAULT_BG_MUSIC_PATH,
    DriftCorrectingTimer,
    MediaPlayer,
    isBrowser,
    setAnimationsEnabled,
    setFullScreen,
    vibrate
  } from "../js/media.ts";
  import { ROUTE_STATS } from "../js/routes.ts";
  import { getStats, preventScreenSleep } from "../js/session.ts";
  import {
    SETTINGS,
    addSessionStats,
    getSetting,
    isSettingEnabled
  } from "../js/settings.ts";
  import { round } from "../js/utils.ts";

  export let f7router;
  export let notui = false;
  export let intro = false;
  export let duration = 5;

  let progressRef = 0;

  const props = {
    // when it's not a UI event disable wake lock use
    notui: notui === true ? false : true,
    intro: intro,
    duration: duration,
  };

  var isPaused;
  var useWakeLock = props.notui === true ? false : true; // when it's not a UI event disable wake lock use

  var buttonVisible = true;
  const setPauseButtonVisible = (visible) => {
    if (visible === buttonVisible) return;
    const opacity = visible ? 1 : 0;
    const duration = visible ? 400 : 400; // show faster than hide
    buttonVisible = visible;
    if (buttonVisible) {
      schedulePauseButtonHide(); // autohide
    }
    f7.$(".fab.fab-center-bottom.fab-morph").css({ opacity: opacity });
    // issue with: animate({ 'opacity': opacity }, { duration: duration });
  };

  var blockSleep = (block) => {
    if (useWakeLock) {
      preventScreenSleep(block);
    }
  };

  var buttonTimer = null;
  const schedulePauseButtonHide = () => {
    // cancel previous timer is any
    if (buttonTimer !== null) {
      window.clearTimeout(buttonTimer);
    }
    // schedule hide
    buttonTimer = window.setTimeout(() => {
      setPauseButtonVisible(false);
      buttonTimer = null;
    }, 3000); // 3 sec hide
  };

  const toggleButton = () => {
    setPauseButtonVisible(!buttonVisible); // show / hide
  };

  const setAnimationsEnabld = (enabled) => {
    setAnimationsEnabled(f7.$(".landscape")[0], enabled);
  };

  const updateProgress = (pct) => {
    progressRef = pct; // update progress
  };

  const navigateStats = () => {
    f7router.navigate(ROUTE_STATS, {
      animate: true,
      clearPreviousHistory: true
    });
  };

  const navigateHome = () => {
    f7router.navigate("/", {
      animate: true,
      clearPreviousHistory: true
    });
  };

  const startDate = new Date();
  let ignoredSeconds = 0; // ignore stopped time, when user stops but continues.

  const finishPushed = () => {
    showFinishDialog(getElapsedTimeSecs());
  };

  const getElapsedTimeSecs = () => {
    return (
      Math.round((Date.now() - startDate.getTime()) / 1000) - ignoredSeconds
    ); // cleanup to int, add stopped time
  };

  var finishDialog;
  const showFinishDialog = (timeSecs) => {
    if (finishDialog) return; // already shown
    const finishDialogShowTime = new Date();

    // pause session
    sessionPause(true);

    const mins = round(timeSecs / 60, 1); // round cleanup
    const stats = getStats();
    const daysStr = tr("session.daysinrow", { days: stats.currentStreak });
    const comeback = tr("session.comeback");
    var content = `
              <div class="grid grid-cols-2 finish-dialog">
                <div><i class="icon material-icons icon-badge">military_tech</i></div>
                <div>
                  <p><b>${tr("session.finished", { minutes: mins })}</b></p>
                  <p>${daysStr}.  <a class="stats-link">${tr("settings.statistics")}</a></p>
                </div>
              </div>
              `;
    // content += `<div class="text-align-center">{comeback}</div>`

    const incrementStoppedTime = () => {
      const stoptimeSecs = Math.round(
        (Date.now() - finishDialogShowTime.getTime()) / 1000
      ); // cleanup to int
      ignoredSeconds += stoptimeSecs; // increase ignoredSeconds with the time it took to hide the dialog
    };

    const closeAndFinishSession = () => {
      incrementStoppedTime();
      // stop session
      sessionStop(true); // save stats
    };

    finishDialog = f7.dialog.create({
      content: content,
      backdrop: false,
      destroyOnClose: true,
      title: '<div class="text-align-center">Coherence</div>',
      on: {
        open: function (dialog) {
          // close dialog and show stats on link click
          f7.$(".stats-link").once("click", () => {
            // SHOW STATS pushed
            dialog.close();
            closeAndFinishSession();
            navigateStats();
          });
          // animate badge
          f7.$(".icon-badge").animate({ opacity: 1 }, { duration: 1000 });
        },
        closed: function () {
          finishDialog = null; // cleanup
        }
      },
      buttons: [
        {
          text: tr("common.finish")
        },
        {
          text: tr("common.continue")
        }
      ],
      onClick: function (dialog, index) {
        dialog.close();
        if (index === 1) {
          // CONTINUE pressed
          incrementStoppedTime(); // add stopped time while the dialog was opened
          sessionPause(false); // unpause session
        } else {
          // FINISH pressed
          closeAndFinishSession();
          navigateHome();
        }
      }
    });
    finishDialog.open();
  };

  const onSessionComplete = () => {
    // TODO hide settings if shown

    showFinishDialog(getElapsedTimeSecs());
    // play end son if any sound enabled
    if (
      isSettingEnabled(SETTINGS.sound_effects_enabled) ||
      isSettingEnabled(SETTINGS.background_music_enabled)
    ) {
      finishedMediaPlayer.start();
    }
  };

  const firstSession = props.intro || false;
  const backgroundMediaPlayer = new MediaPlayer(DEFAULT_BG_MUSIC_PATH); // looping
  const breathInMediaPlayer = new MediaPlayer(BREATH_IN_PATH, false); // not looping
  const breathOutMediaPlayer = new MediaPlayer(BREATH_OUT_PATH, false); // not looping
  const finishedMediaPlayer = new MediaPlayer(CHIMES_1, false); // not looping

  var vibrationIter = 0;
  var vibrationEnabled = isSettingEnabled(SETTINGS.vibrations_enabled);
  const vibrateTimerFunc = () => {
    if (!vibrationEnabled) return;
    if (vibrationIter < 5) vibrate(100); // VIBRATE
    vibrationIter++;
  };

  var countDownDate;
  const minutes = firstSession ? 2 : props.duration || 5; // 2 minutes first time, or 5 min default

  const animationTime = getSetting(SETTINGS.breathing_time) || 5;
  if (!animationTime)
    throw new Error(`Invalid animationTime = ${animationTime}`);

  let timerRef = "";
  const updateTimerText = (text) => {
    timerRef = text;
  };

  var countDownComplete = false;
  const timerFunc = () => {
    if (!countDownDate) return;
    // Get today's date and time
    const now = new Date().getTime();
    // Find the distance between now and the count down date
    const distance = countDownDate - now + ignoredSeconds * 1000;
    // Time calculations for days, hours, minutes and seconds
    // const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    // const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let mins = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let secs = Math.floor((distance % (1000 * 60)) / 1000);
    let breaths = Math.ceil((secs + mins * 60) / animationTime / 2);
    // Display the result in the element with id="timer"
    if (distance < 0) {
      // TODO use ref variable, better math, use constants
      mins = Math.ceil(mins - (distance % (1000 * 60 * 60)) / (1000 * 60));
      secs = Math.ceil((distance % (1000 * 60)) / 1000);
      breaths = Math.floor((secs + mins * 60) / animationTime / 2);
    }

    let timerText = `${tr("session.time_left", { mins: mins, secs: secs })}, ${tr("session.breath_left", { breaths: breaths })}`;
    timerText = timerText.replace(/-/g, "+"); // show extra time as + instead of -
    updateTimerText(timerText);

    const progress = 100 - Math.round((100 * distance) / (minutes * 60 * 1000));
    // If the count down is finished, write some text
    // TODO: find another way the ncountDownComplete to not repeat when timer is finished
    if (distance < 0 && !countDownComplete) {
      updateTimerText("Done");
      updateProgress(100); // max out progress bar
      onSessionComplete();
      countDownComplete = true;
    } else {
      // update progress
      if (progress < 0) {
        console.error("bad progress", progress);
      }
      updateProgress(progress);
      f7.progressbar.set("#progress", progress);
    }
  };

  const onBreathChange = (isBreathIn) => {
    if (isSettingEnabled(SETTINGS.sound_effects_enabled)) {
      if (isBreathIn) {
        breathInMediaPlayer.start();
        breathOutMediaPlayer.stop();
      } else {
        breathOutMediaPlayer.start();
        breathInMediaPlayer.stop();
      }
    }

    // vibrations
    vibrateTimer.stop(); // stop vibration to make sure we're in sync
    vibrationIter = 0; // dirty hacky
    if (isBreathIn) {
      vibrateTimer.start(); // resync vibration to animation
    }
  };

  var breathIn = true;
  const breathTimerFunc = () => {
    onBreathChange(breathIn);
    breathIn = !breathIn;
  };

  const breathTimer = new DriftCorrectingTimer(
    breathTimerFunc,
    animationTime * 1000
  );
  const vibrateTimer = new DriftCorrectingTimer(vibrateTimerFunc, 1000);
  const elapsedTimer = new DriftCorrectingTimer(timerFunc, 100); // update every 100ms

  const firstStart = () => {
    sessionStart();

    // after this initial start we can use the wake lock for sure
    useWakeLock = true;

    if (isBrowser()) {
      setFullScreen(true);
    }
  };

  const sessionStart = () => {
    countDownDate = new Date(Date.now() + minutes * 60 * 1000).getTime();
    ignoredSeconds = 0; // reset ignored seconds. TODO: is this needed ?

    sessionPause(false);
    // let listeners know we're starting a session
    dispatch(EVENT.sessionStateChange, true);
    // analytics
    recordEventSessionStart(minutes);
  };

  function sessionStop(saveStats = true) {
    const completedSecs = getElapsedTimeSecs();
    if (saveStats) {
      addSessionStats({
        // store stats
        date: startDate.getTime(),
        elapsed: completedSecs
      });
    }
    // let listeners know we're ending a session
    dispatch(EVENT.sessionStateChange, false);
    // analytics
    if (saveStats && completedSecs > 1) {
      recordEventSessionEnd(completedSecs / 60); // record minutes
    }
  }

  function sessionPause(paused) {
    isPaused = paused;

    if (paused) {
      // pause
      elapsedTimer.stop();
      breathTimer.stop();
      vibrateTimer.stop();
      backgroundMediaPlayer.stop();
      breathInMediaPlayer.stop();
      breathOutMediaPlayer.stop();
    } else {
      // start
      elapsedTimer.start();
      if (isSettingEnabled(SETTINGS.background_music_enabled)) {
        backgroundMediaPlayer.start();
      }
      breathTimer.start();
      schedulePauseButtonHide();
    }

    setAnimationsEnabld(!paused);
    updateMessageEnabled(!paused);
    blockSleep(!paused);
  }

  const updateMessageEnabled = (enabled) => {
    const msgEnabled = enabled
      ? isSettingEnabled(SETTINGS.messages_enabled)
      : false;
    const msgEl = f7.$("#messages");
    if (msgEl) {
      if (msgEnabled) {
        msgEl.show();
      } else {
        msgEl.hide();
      }
    }
  };

  const onSettingsChange = (settings) => {
    console.debug("settings changed" + JSON.stringify(settings));
    // reloadSettings(); // change global settings state
    if (!isSettingEnabled(SETTINGS.background_music_enabled)) {
      backgroundMediaPlayer.stop();
    } else {
      backgroundMediaPlayer.start();
    }

    const vibrationEnabledPrev = vibrationEnabled;
    vibrationEnabled = isSettingEnabled(SETTINGS.vibrations_enabled);
    if (vibrationEnabled && vibrationEnabledPrev != vibrationEnabled) {
      vibrate(100); // tell user we enabled vibrations
    }
    updateMessageEnabled(true);
  };

  function onPageInit() {
    subscribe(EVENT.settingsChanged, onSettingsChange);
    // fab wiil force button visible so state needs to know
    f7.$(".fab").on("fab:close", () => {
      setPauseButtonVisible(true);
    });
  }

  function onPageBeforeIn() {
    // update animation time
    document.documentElement.style.setProperty(
      "--anim-time",
      `${animationTime}s`
    );
    document.documentElement.style.setProperty(
      "--anim-time-2",
      `${animationTime * 2}s`
    );

    // TODO: this should work but it doesn't...
    // $(document.documentElement).css({
    //   '--anim-time': `${animationTime}s`,
    //   '--anim-time-2': `${animationTime * 2}s`
    // });

    // bit hacky hide messages now (early) or they'll be visible for a little while when disabled
    updateMessageEnabled(false);
  }

  function onpageAfterIn() {
    // start session
    firstStart();
  }

  function onPageBeforeOut() {
    // when page is reloaded by router, make sure we close the dialog
    // TODO: can be called by router.refreshPage() here so check it's all done right
    if (!isPaused) {
      sessionPause(true);
    }
    if (finishDialog) {
      finishDialog.close();
    }
    if (isBrowser()) {
      setFullScreen(false);
    }
  }

  function onPageBeforeRemove() {
    f7.$("#live-settings").off("form:storedata"); // remove listener
    unsubscribe(EVENT.settingsChanged, onSettingsChange);
  }
</script>

<Page class="session-sunrise no-default-bg" name="sunrise"
  onPageInit={onPageInit}
  onPageAfterIn={onpageAfterIn}
  onPageBeforeIn={onPageBeforeIn}
  onPageBeforeOut={onPageBeforeOut}
  onPageBeforeRemove={onPageBeforeRemove}
>
  <!-- pause button -->
  <div
    class="fab fab-center-bottom fab-morph"
    data-morph-to=".fab-fullscreen-sheet.fab-morph-target">
    <a href={"#"} class="link icon-only">
      <i class="icon material-icons icon-pause">settings</i>
    </a>
  </div>

  <!-- center sheet popup for settings -->
  <div class="fab-fullscreen-sheet fab-morph-target">
    <div class="view view-init">
      <div class="page no-default-bg settings-page">
        <div class="page-content">
          <SettingsComponent />

          <div class="block-title" id="timer">{timerRef}</div>
          <div class="block">
            <!-- Green progressbar -->
            <div
              id="progress"
              class="progressbar color-green"
              data-progress={progressRef}>
              <span></span>
            </div>
          </div>

          <div class="block">
            <div class="grid grid-cols-2 grid-gap">
              <div>
                <a
                  href={"#"}
                  class="button button-round button-fill color-deeporange fab-close"
                  on:click={finishPushed}>{tr("common.finish")}</a>
              </div>
              <div>
                <a
                  href={"#"}
                  class="button button-round button-fill color-teal fab-close"
                  >{tr("common.continue")}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div class="landscape" on:click={toggleButton}>
    <div class="mountain"></div>
    <div class="mountain mountain-2"></div>
    <div class="mountain mountain-3"></div>
    <div class="sun-container sun-container-1"></div>
    <div class="sun-container">
      <div id="sun" class="sun"></div>
    </div>
    <div class="cloud"></div>
    <div class="cloud cloud-1"></div>
    <div class="sun-container sun-container-reflection">
      <div class="sun"></div>
    </div>
    <div class="light"></div>
    <div class="light light-1"></div>
    <div class="light light-2"></div>
    <div class="light light-3"></div>
    <div class="light light-4"></div>
    <div class="light light-5"></div>
    <div class="light light-6"></div>
    <div class="light light-7"></div>
    <div class="water"></div>
    <div class="splash"></div>
    <div class="splash delay-1"></div>
    <div class="splash delay-2"></div>
    <div class="splash splash-4 delay-2"></div>
    <div class="splash splash-4 delay-3"></div>
    <div class="splash splash-4 delay-4"></div>
    <div class="splash splash-stone delay-3"></div>
    <div class="splash splash-stone splash-4"></div>
    <div class="splash splash-stone splash-5"></div>
    <div class="lotus lotus-1"></div>
    <div class="lotus lotus-2"></div>
    <div class="lotus lotus-3"></div>
    <div class="front">
      <div class="stone"></div>
      <div class="grass"></div>
      <div class="grass grass-1"></div>
      <div class="grass grass-2"></div>
      <div class="reed"></div>
      <div class="reed reed-1"></div>
    </div>
  </div>
  <!-- landscape -->

  <MessageComponent intro={firstSession} />
</Page>
