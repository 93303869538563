<script>
  import { onMount } from "svelte";
  import { browseURL, getAppInfo } from "../../js/app.ts";
  import { translate as tr } from "../../js/i18n.ts";

  export let f7router;
  var clickCount = 0;
  const showDebugPage = () => {
    clickCount++;
    if (clickCount % 5 == 0) {
      // every 5 clicks
      f7router.navigate("/debug");
    }
  };
</script>

<div class="page" data-name="about">
  <div class="navbar">
    <div class="navbar-inner sliding">
      <div class="left">
        <a href={"#"} class="link back">
          <i class="icon icon-back"></i>
          <span class="if-not-md">Back</span>
        </a>
      </div>
      <div class="title">{tr("settings.about")}</div>
    </div>
  </div>
  <div class="page-content">
    <div class="block-title">{tr("about.slogan")}</div>
    <div class="block">
      <p innerHTML={tr("about.description")}></p>
      <p innerHTML={tr("about.free_descr")}></p>
    </div>
    <div class="list">
      <ul>
        <li>
          <a
            href={"#"}
            class="item-link item-content"
            on:click={() =>
              browseURL(
                "https://coherence-app.com/about-coherence/?utm_source=app&utm_content=more_info_link"
              )}>
            <div class="item-media">
              <i class="icon material-icons">help_outline</i>
            </div>
            <div class="item-inner">
              <div class="item-title">{tr("about.more_info")}</div>
            </div>
          </a>
        </li>
        <li>
          <a
            href={"#"}
            class="item-link item-content"
            on:click={() =>
              browseURL(
                "https://coherence-app.com/support-us?utm_source=app&utm_content=about_support_us_link"
              )}>
            <div class="item-media">
              <i class="icon material-icons">favorite</i>
            </div>
            <div class="item-inner">
              <div class="item-title">{tr("about.support_us")}</div>
            </div>
          </a>
        </li>
        <li>
          <a
            href={"#"}
            class="item-link item-content"
            on:click={() =>
              browseURL(
                "https://coherence-app.com?utm_source=app&utm_content=website_link"
              )}>
            <div class="item-media">
              <i class="icon material-icons">public</i>
            </div>
            <div class="item-inner">
              <div class="item-title">{tr("about.website")}</div>
            </div>
          </a>
        </li>
        <li>
          <a href="/introduction/" class="item-link item-content">
            <div class="item-media">
              <img src="/icons/icon.svg" alt="Coherence Logo" />
            </div>
            <div class="item-inner">
              <div class="item-title">{tr("about.show_intro")}</div>
            </div>
          </a>
        </li>
        <li>
          <a
            href={"#"}
            on:click={() => browseURL("mailto:hello@coherence-app.com")}
            class="item-link item-content">
            <div class="item-media">
              <i class="icon material-icons">email</i>
            </div>
            <div class="item-inner">
              <div class="item-title">{tr("about.send_feedback")}</div>
            </div>
          </a>
        </li>
        <li>
          <a href="/settings/privacy/" class="item-link item-content">
            <div class="item-media">
              <i class="icon material-icons">privacy_tip</i>
            </div>
            <div class="item-inner">
              <div class="item-title">{tr("privacy.title")}</div>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
    <p
      on:click={showDebugPage}
      style="position: absolute; bottom: 0; right: 16px;">
      {#await getAppInfo()}
        <p>v...</p>
      {:then appInfo}
        v{appInfo.version}
      {:catch error}
        <p style="color: red">{error.message}</p>
      {/await}
    </p>
  </div>
</div>
