import { Haptics } from '@capacitor/haptics';
import { Howl } from 'howler';
import { device, logError, runAndLogErrors } from './app.ts';

export const BELL_1 = '/media/bell_1.mp3';
export const BELL_2 = '/media/bell_2.mp3';
export const CHIMES_1 = '/media/chimes_1.mp3';

export const DEFAULT_BG_MUSIC_PATH = '/media/background_music.mp3';
export const BREATH_IN_PATH = BELL_1;
export const BREATH_OUT_PATH = BELL_2;

export function isBrowser() {
  return device.web;
}

export function getMediaUrl(url: string) {
  return url;
}

export class MediaPlayer {

  url: string;
  loop: boolean;
  playing: boolean;
  media: Howl | null;

  constructor(url: string, loop = true) {
    // convert url for different devices
    this.url = url;
    this.loop = loop;
    this.playing = false;

    this.media = new Howl({
      src: [url],
      loop: loop
    });
  }

  async start() {
    if (!this.media) { // no media player available
      return;
    }

    // play
    console.debug(`MediaPlayer:play(${this.url})`);
    this.media.volume(1.0); // reset in case it was faded out
    this.media.play();
    this.playing = true;
  }

  async pause() {
    if (!this.media) {
      return;
    }
    console.debug(`MediaPlayer:pause(${this.url})`);

    this.media.pause();
    this.playing = false;
  }

  async stop() {
    if (!this.media) {
      return;
    }
    console.debug(`MediaPlayer:stop(${this.url})`);

    const md = this.media;
    // listen to the fade event and then stop
    this.media.once('fade', () => { md.stop() });
    // fade out, then stop
    this.media.fade(this.media.volume(), 0, 300);
    this.playing = false;
  }
}


export const vibrate = async (ms: number) => {
  // TODO should be checked only once but device not available before init
  const vibrateBrowserSupport = window.navigator && typeof window.navigator.vibrate !== 'undefined';
  const vibrateSupported = device.native ? true : vibrateBrowserSupport;

  if (!vibrateSupported) {
    console.warn("Vibration is not supported on this platform");
  }

  if (!vibrateSupported) {
    return;
  }
  try {
    if (device.native) {
      await Haptics.vibrate({ duration: ms })
    } else {
      window.navigator.vibrate(ms);
    }
  } catch (err) {
    logError('vibrate error', err);
  }
}

/**
* Self-adjusting interval to account for drifting
*
* @param {function} workFunc  Callback containing the work to be done
*                             for each interval
* @param {int}      interval  Interval speed (in milliseconds)
* @param {boolean}  runOnStart (true) to run workFunc() when the timer starts
**/
export class DriftCorrectingTimer {
  expected: null | number = null;
  timeout: null | number = null;
  interval;
  runOnStart;
  workFunc;

  constructor(workFunc: Function, interval: number, runOnStart: boolean = true) { // eslint-disable-line
    this.workFunc = workFunc;
    this.interval = interval;
    this.runOnStart = runOnStart;
  }

  start() {
    if (this.timeout) {
      throw new Error('Timer already started');
    }
    this.expected = Date.now() + this.interval;
    // bind this to "this" timer class on the timeout callback
    this.timeout = setTimeout(this.step.bind(this), this.interval);

    if (this.runOnStart) {
      this.workFunc();
    }
  }

  stop() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = null;
  }

  step() {
    if (this.timeout == null || this.expected == null) { // stopped
      return;
    }

    const drift = Date.now() - this.expected;
    this.workFunc(); // call function

    if (this.timeout == null) { // stopped during workFunc
      return;
    }

    this.expected += this.interval;
    this.timeout = setTimeout(this.step.bind(this), Math.max(0, this.interval - drift));
  }
}

export function setFullScreen(full: boolean) {
  if (!document.fullscreenEnabled) {
    console.warn('full screen is not enabled for browser')
    return;
  }

  runAndLogErrors(() => {
    if (full) {
      if (!document.fullscreenElement) { // already fullscreen
        if (typeof document.documentElement.requestFullscreen === 'function') {
          document.documentElement.requestFullscreen().catch((err) => logError('requestFullscreen() error', err));
        }
      } else {
        logError('setFullScreen(): Already fullscreen');
      }
    } else {
      if (document.exitFullscreen && document.fullscreenElement) {
        document.exitFullscreen().catch((err) => logError('exitFullscreen() error', err));
      }
    }
  });
}

/**
 * Pause animations on a all children of a DOM element
 * 
 */
export const setAnimationsEnabled = (el: Element, enabled: boolean): void => {
  //Get all the AnimationPlayers
  let animatedEls;

  // TODO only does this element use el.getAnimations({subtree: true});
  if (typeof el.getAnimations === 'function') {
    animatedEls = el.getAnimations({ subtree: true });
  } else {
    console.error(`Cannot get element: ${el} animations, animations will not be constrolled`)
    return;
  }

  if (animatedEls && animatedEls.length) {
    console.debug(`setAnimationEnabled(${enabled}): ${animatedEls.length}`);
    animatedEls.forEach(function (player) {
      if (enabled)
        player.play()
      else
        player.pause()
    });
  } else {
    console.log('No active animations');
  }
}

