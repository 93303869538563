<script>
  import { Navbar, Page, PageContent } from "framework7-svelte";
  import { onDestroy, onMount } from "svelte";

  import {
    browseURL,
    device,
    getAppInfo,
    isDevMode,
    logError
  } from "../js/app.ts";
  import { DriftCorrectingTimer } from "../js/media.ts";
  import { isDebugUser } from "../js/settings.ts";

  let appInfo;
  let consoleHistory = console.history;

  // resolve promise ans set variable
  getAppInfo().then((data) => (appInfo = data));

  const openWebsite = async () => {
    await browseURL("https://coherence-app.com");
  };

  const testDebug = () => {
    const asyncTest = async () => {
      throw Error("Async test error");
    };

    setTimeout(() => {
      asyncTest();
    }, 3000);

    throw Error("test error");
  };

  const refresh = () => {
    consoleHistory = console.history; // force refresh console
  };

  const refreshTimer = new DriftCorrectingTimer(refresh, 1000);

  onMount(() => refreshTimer.start());
  onDestroy(() => refreshTimer.stop());
</script>

<Page name="about">
  <Navbar title="Debug Information" backLink="Back" />
  <PageContent>
    <div class="grid grid-cols-2 grid-gap">
      <div>
        <div>
          <a
            href={"#"}
            class="button button-fill color-green"
            on:click={testDebug}>Test</a>
        </div>
      </div>
      <div>
        <div>
          <a href={"#"} class="button button-fill color-blue" on:click={refresh}
            >Refresh</a>
        </div>
      </div>
    </div>
    <div class="block-title">Device & App Info</div>
    <pre>{device &&
        JSON.stringify(
          {
            ...device,
            ...appInfo,
            isDebugUser: isDebugUser(),
            isDevMode: isDevMode
          },
          null,
          2
        )}
    </pre>
    <table>
      <thead>
        <tr>
          <th class="label-cell">Date</th>
          <th class="label-cell">Lvl</th>
          <th class="label-cell">Log</th>
        </tr>
      </thead>
      <tbody>
        {#each consoleHistory as log}
          <tr>
            <td class="label-cell"
              >{new Date(log.timestamp).toLocaleTimeString("fr-FR")}</td>
            <td class="label-cell">{log.type.slice(0, 1)}</td>
            <td class="label-cell">{log.arguments[0]}</td>
          </tr>
        {/each}
      </tbody>
      <div>
        <div></div>
      </div>
    </table>
  </PageContent>
</Page>
