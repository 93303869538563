<script>
  import { Page, Navbar, Block } from "framework7-svelte";
</script>

<Page>
  <Navbar title="Lost your way?" backLink="Back" />
  <img
    class="image bottom"
    src="https://coherence-app.com/404.webp"
    alt="not found"
    style="" />
</Page>

<style>
  .bottom {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
  }
</style>
