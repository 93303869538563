import { Router } from 'framework7/types';
import { SETTINGS, getSetting, isFirstStart } from '../js/settings.ts';

import NotFoundPage from '../pages/404.svelte';
import DebugPage from '../pages/debug.svelte';
import HomePage from '../pages/home.svelte';
import IntroPage from '../pages/introduction.svelte';
import SettingsPage from '../pages/settings.svelte';
import AboutPage from '../pages/settings/about.svelte';
import LicensesPage from '../pages/settings/licenses.svelte';
import NotificationSettingsPage from '../pages/settings/notifications.svelte';
import PrivacyPage from '../pages/settings/privacy.svelte';
import SessionSettingsPage from '../pages/settings/session.svelte';
import StatsPage from '../pages/settings/stats.svelte';
import CoherenceSessionPage from '../pages/sunrise.svelte';
import TimerPage from '../pages/timer.svelte';

export const ROUTE_INTRODUCTION = '/introduction';
export const ROUTE_SETTINGS = '/settings/';
export const ROUTE_SESSION = '/sunrise/';
export const ROUTE_SESSION_5M = `${ROUTE_SESSION}5`;
export const ROUTE_STATS = `${ROUTE_SETTINGS}statistics/`;
export const ROUTE_TIMER = '/timer/';
export const ROUTE_DEBUG = '/debug';
export const ROUTE_HOME = '/';

let showIntroPage = isFirstStart();

// issues with asynComponents https://github.com/framework7io/framework7/issues/4097
const routes: Router.RouteParameters[] = [
  {
    path: '/',
    // dynamically resolve the home page based on first start status
    // asyncComponent: () => showIntroPage ? import('../pages/home.svelte') : import('../pages/home.svelte'),
    component: showIntroPage ? IntroPage : HomePage,
    options: {
      animate: false,
      transition: 'f7-fade',
    },
  },
  {
    path: ROUTE_SESSION + ':duration', // duration of session in minutes
    // asyncComponent: () => import('../pages/sunrise.f7'),
    component: CoherenceSessionPage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: ROUTE_TIMER + ':duration', // duration of time in minutes,
    // asyncComponent: () => import('../pages/timer.svelte'),
    component: TimerPage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: ROUTE_INTRODUCTION,
    // asyncComponent: () => import('../pages/introduction.svelte'),
    component: IntroPage,
    options: {
      animate: false,
    },
  },
  {
    path: ROUTE_SETTINGS,
    // asyncComponent: () => import('../pages/settings.svelte'),
    component: SettingsPage,
    options: {
      transition: 'f7-parallax',
    },
  },
  {
    path: `${ROUTE_SETTINGS}notifications/`,
    //asyncComponent: () => import('../pages/settings/notifications.svelte'),
    component: NotificationSettingsPage,
    options: {
      transition: 'f7-parallax',
    },
  },
  {
    path: `${ROUTE_SETTINGS}session/`,
    // asyncComponent: () => import('../pages/settings/session.f7'),
    component: SessionSettingsPage,
    options: {
      transition: 'f7-parallax',
    },
  },
  {
    path: `${ROUTE_SETTINGS}licenses/`,
    // asyncComponent: () => import('../pages/settings/licenses.svelte'),
    component: LicensesPage,
    options: {
      transition: 'f7-parallax',
    },
  },
  {
    path: `${ROUTE_SETTINGS}about/`,
    // asyncComponent: () => import('../pages/settings/about.svelte'),
    component: AboutPage,
    options: {
      transition: 'f7-parallax',
    },
  },
  {
    path: `${ROUTE_SETTINGS}privacy/`,
    //asyncComponent: () => import('../pages/settings/privacy.f7'),
    component: PrivacyPage,
    options: {
      transition: 'f7-parallax',
    },
  },
  {
    path: ROUTE_STATS,
    // asyncComponent: () => import('../pages/settings/stats.svelte'),
    component: StatsPage,
    options: {
      transition: 'f7-parallax',
    },
  },
  {
    path: ROUTE_DEBUG,
    // asyncComponent: () => import('../pages/debug.svelte'),
    component: DebugPage,
    options: {
      transition: 'f7-parallax',
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];


export const navigateSession = (f7router: Router.Router, minutes?: number, props?: object) => {
  if (!minutes) { // use settings
    minutes = getSetting(SETTINGS.default_session_time) as number;
  }
  f7router.navigate(`${ROUTE_SESSION}${minutes}`, {
    history: false,
    browserHistory: false,
    ...props
  });
};

export const setShowIntroPage = (show: boolean) => {
  showIntroPage = show;
  // Dirty hack, replace route componen dynamically
  routes[0].component = showIntroPage ? IntroPage : HomePage;
}

export default routes;

