import { LocalNotifications, LocalNotificationSchema } from '@capacitor/local-notifications';
import { ForegroundService } from "@capawesome-team/capacitor-android-foreground-service";
import Framework7 from 'framework7/bundle';
import translate, { translateWithUsername } from '../js/i18n.ts';
import { preventScreenSleep } from '../js/session.ts';
import { getStoredValue, setStoredValue, StoreValue } from '../js/settings.ts';
import { Event, recordEvent } from './analytics.ts';
import { device, logAndShowError, logError } from './app.ts';
import { ROUTE_SESSION_5M } from './routes.ts';

export const ICON_SMALL = 'ic_notification';
export const ICON_LARGE = 'ic_notification_large';

interface NotificationTime {
    hour: number,
    minute: number
}

function hasNotificationPlugin(): boolean {
    return device.native && typeof LocalNotifications !== 'undefined';
}

export function getStoredNotificationTimes(): NotificationTime[] {
    const defaultNotifications = [
        { hour: 8, minute: 0 },
        { hour: 13, minute: 0 },
        { hour: 18, minute: 0 },
    ]

    return getStoredValue(StoreValue.notifications, defaultNotifications);
}

/**
 * Stores the notifications settings and re-schedule notifications
 * 
 * @param notifs a array of notifications to schedule
 */
export function setStoredNotificationTimes(notifs: NotificationTime[], scheduleNow: boolean = true) {
    // sanitize array
    const cleanNotifs: NotificationTime[] = Array.from(notifs, (n) => {
        return { hour: n.hour, minute: n.minute }
    });
    // sort notifications by hour
    cleanNotifs.sort(function (a, b) { return a.hour - b.hour });
    // TODO: sort by hour/time
    setStoredValue(StoreValue.notifications, cleanNotifs);
    // re-schedule notifications
    if (scheduleNow) {
        scheduleNotifications(true); // cancel if there's no notifications saved
    }
}

export function getWelcomeMessage(hourOfDay: number): [string, string] {
    let timeOfDay = 'morning';
    if (hourOfDay >= 12 && hourOfDay < 18) {
        timeOfDay = 'afternoon';
    } else if (hourOfDay <= 3 || hourOfDay >= 18) {
        timeOfDay = 'evening';
    }
    return [
        translateWithUsername('home.welcome_message', { context: timeOfDay }),
        translate('messages.notification', { context: timeOfDay }),
    ]
}

async function scheduleStoredNotifications(notifTimes: NotificationTime[]) {
    const schedules: LocalNotificationSchema[] = [];
    const START_ID = 10;
    // cancel all notifications first
    try {
        await cancelAll()
    } catch (err) {
        logError("Notifications:cancelAll() caught error", err)
    }
    // create notifications
    for (const ntime of notifTimes) {
        const [title, message] = getWelcomeMessage(ntime.hour);
        // split on the exclam
        // add to the scheduled notifications
        schedules.push({
            id: START_ID + schedules.length,
            title: title, // TODO remove split and do separate trs
            body: message,
            // smallIcon: ICON_SMALL, set in capacitor.config.js:LocalNotifications
            schedule: {
                on: {
                    hour: ntime.hour,
                    minute: ntime.minute,
                    second: 0
                },
                allowWhileIdle: true
            }
        })
    }
    // schedule
    try {
        if (schedules.length) {
            await LocalNotifications.schedule({
                notifications: schedules
            });
        }
        console.debug(`Notifications: scheduled count=${schedules.length}`, schedules)
        return schedules;

    } catch (err) {
        logError("Notifications:schedule() caught error", err)
    }
}

async function cancelAll() {
    const pendingNotifications = await LocalNotifications.getPending()
    const count = pendingNotifications?.notifications?.length || 0
    if (count > 0) {
        await LocalNotifications.cancel({
            notifications: pendingNotifications.notifications
        })
    }
    console.debug(`Notifications: cancelled count=${count}`)
}

export async function scheduleNotifications(scheduleWhenEmpty: boolean = false) {
    if (!hasNotificationPlugin()) return;

    const GRANTED = 'granted'
    const notifTimes = getStoredNotificationTimes();
    if (notifTimes.length === 0 && !scheduleWhenEmpty) {
        console.debug(`Notifications: no notifications to be scheduled`);
        return;
    }

    try {
        let res = await LocalNotifications.checkPermissions();
        if (res.display === GRANTED) {
            return await scheduleStoredNotifications(notifTimes) // schedule
        } else { // no permissions, request them
            try {
                // ask 3 times
                for (let requestedCount = 0; requestedCount < 3; requestedCount++) {
                    res = await LocalNotifications.requestPermissions();
                    if (res.display === GRANTED) {
                        return await scheduleStoredNotifications(notifTimes) // schedule
                    } else {
                        console.warn(`Notifications: user did not grant permissions count=${requestedCount} data=${JSON.stringify(res)}`);
                    }
                }
            }
            catch (err) {
                logError('Notifications:requestPermissions() caught an error', err)
            }
        }
    }
    catch (err) {
        logError('Notifications:checkPermissions() caught an error', err)
    }
}

const SESSION_NOTIF_ID = 5;
export const showSessionNotification = async () => {
    if (!hasNotificationPlugin()) {
        console.warn('showSessionNotification(): No notifications plugin available, notifications disabled');
        return;
    }

    await LocalNotifications.schedule({
        notifications: [
            {
                id: SESSION_NOTIF_ID,
                title: 'Coherence',
                autoCancel: true,
                ongoing: false,
                body: "Ongoing session, click here to come back",
                // smallIcon: ICON_SMALL, set in capacitor.config.js:LocalNotifications
            }
        ]
    });
    console.debug(`showSessionNotification()`);
}

export const hideSessionNotification = async () => {
    if (!hasNotificationPlugin()) {
        console.warn('hideSessionNotification(): No notifications plugin available, notifications disabled');
        return;
    }

    await LocalNotifications.removeAllDeliveredNotifications()
    console.debug(`hideSessionNotification()`);
}

export const onNotificationClick = (f7: Framework7) => {
    // record as pageview for stats
    recordEvent(Event.PageView, { page_location: '/notifications/scheduled' });
    // start coherence session immediately
    const router = f7.views.main?.router; // needs the view's router instance to work
    if (router) {
        router.navigate(ROUTE_SESSION_5M, {
            reloadCurrent: true, // force refresh
            ignoreCache: true, // force refresh
            clearPreviousHistory: true, // clear history
            history: false, // don't add this route to history
            browserHistory: false, // don't add this route to history
            props: {
                notui: true, // indicate user did not interact with screen (no click event)
            },
        });
        // this can fail because it's not coming from a user event
        // but will work on visibility change later
        preventScreenSleep(true, true); // delay lock
    } else {
        logAndShowError('Local notification clicked but no view/router available');
    }
}

/**
 * Setup notifications for application
 * 
 * @param f7 the Framework7 app instance
 */
export const setupNotifications = async (f7: Framework7) => {
    if (!hasNotificationPlugin()) {
        console.warn('No notifications plugin available, notifications disabled');
        return;
    }

    // click notification listener
    await LocalNotifications.addListener('localNotificationActionPerformed', () => onNotificationClick(f7));

    // check permissions and schedule notifications
    scheduleNotifications(false); // not scheduling (cancelling) empty notifications
};

export const startForegroundService = async () => {
    if (!device.android) return;

    console.debug("notitications: starting foreground service");
    await ForegroundService.startForegroundService({
        id: 42, // answer to the universe
        title: 'Coherence',
        body: "Ongoing session, click here to come back",
        smallIcon: ICON_SMALL, // forced for this plugin
    });
};

export const stopForegroundService = async () => {
    console.debug("notitications: stopping foreground service");
    if (!device.android) {
        return false;
    }
    await ForegroundService.stopForegroundService();
};

